import React from "react";

export const NotFoundSection = () => {
  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container" style={{ maxWidth: 640 }}>
          <h1 className="title">404</h1>
          <h3 className="subtitle">Oops, page not found</h3>
          {/*
          <div className="buttons">
            <a className="button has-text-weight-bold" href="#">ページに戻る</a>
          </div>
          */}
        </div>
      </div>
    </section>
  );
};
