/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const ErrorOccurredSection = ({
  tryAgain,
}: {
  tryAgain: () => void;
}) => {
  return (
    <section className="hero is-fullheight">
      <div className="hero-body">
        <div className="container" style={{ maxWidth: 640 }}>
          <h1 className="title">Error</h1>
          <h3 className="subtitle">Oops, an unexpected error occurred.</h3>
          <div className="buttons">
            <a className="button has-text-weight-bold" onClick={tryAgain}>
              Try Again
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
